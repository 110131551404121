#heading{
  font-size: 1.9rem;
  font-weight: bold;
  color: #94211d;
  font-family: serif !important;
  text-align: center;
}

.pavlina-section {
  margin-top: 10px;
  max-width: 100%;
}

.pavlina-about {
  display: flex;
  flex-direction: row;
  margin: auto;
  
}

.headshot{
  display: flex;
  /* flex-direction: column;
  justify-content: center; */
  padding-right: 20px;
}
.headshot img{
  width: 330px;
  height: auto;
}

.pavlina-desc, .production-desc{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
  line-height: 1.2em;
  text-align: justify;
  
}

.author {
  font-size: 1.5rem;
  font-weight: bold;
  color: #94211d;
  margin-right: 10%;
  font-family: serif !important;
  text-align: center;
}

.pavlina-section-2 {
  margin-top: 50px;
  margin-bottom: 50px;
  max-width: 100%;
}


.carousel-aboutus{
  display: flex;
  flex-direction: row;
  margin: auto;
  
  text-align: justify;
}


.extras{
  text-align: center;
  width : 80%;
}

.carousel {
  display: flex;
  /* flex-direction: column;
  justify-content: center; */
  margin-right: 10px;
  
}
.carousel img{
  width: 330px;
  height: auto;
}


.hoverable-img {
  transition: transform 0.3s;
}


.hoverable-img:hover {
  transform: scale(1.1);
}



.carousel p{
  color: white;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
 }
 
 .carousel-control-next-icon {
   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
 }

 .carousel-indicators [data-bs-target]{
  background-color: white !important;
}

hr { 
  display: block;
  width: 70%;
  margin: 0 auto; 
  align-self: center;
  border-style: solid;
  margin-top: 2em;
  border-radius: 50%;
  border-width: 2px; /* Increase border width for thickness */
  border-color: #120101; /* Set border color to a darker color */
} 


@media (max-width: 1024px) {
  .carousel { 
    width: 100%; 
    height: 100%;
    margin-right : 0;
    margin-left : 0;
  }
  .carousel img{
    width: 100%;
    height: 100%;
  }
  .about-company-section{
    flex-direction: column;
  }

  .pavlina-about, .carousel-aboutus {
    flex-direction: column;
  }

  .pavlina-desc {
    margin-left: 0;
    margin-top: 20px;
  }
  .headshot img{
    width: 100%;
    height: 100%;
  }
  .headshot, .carousel{
    align-items: center;
  }

  .pavlina-section, .pavlina-section-2{
    margin-left: 20px;
    margin-right: 20px;
  } 
 

  .carousel{
    padding-bottom: 10px ;
  }

}

@media (max-width: 500px) {


  .between-desc-section {
    flex-direction: column;
  }
  
}
