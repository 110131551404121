


.tabs{
  display: flex;
  justify-content: center;
}

.tabs button{
  background-color: #fff;
  font-family: sans-serif;
  padding: 15px;
  width: 300px;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: rgb(199, 201, 203);
  color: black;
  font-weight: 500;
  font-size: 16px;
  }
.tabs button:hover{
  color: #94112D;
  border-color: rgb(241, 239, 239)  rgb(241, 239, 239) #94112D rgb(241, 239, 239);
}
.galleryWrap{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin: 10px auto;
  width: 1300px;
  padding-bottom: 50px;
}

.movieTitle{
  font-family: sans-serif !important;
  padding: 50px;
  font-weight:bold;
  font-size:xx-large;
  text-align: center;
  color:#94112D;

}

.galleryWrap .gallery-item{
  cursor: pointer;

}

.galleryWrap .gallery-item img{
    width: 400px;
    height: 300px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.galleryWrap .gallery-item img:hover{
  transform: scale(1.02);

}
.sliderWrap{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0,0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

}
.orphan{
  background-image: url("./orphan.png");
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover; 
}

.perfume{
  background-image: url("./perfume.png");
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover; 
  
}

.pilgrimage{
  background-image: url("./pilgrimage.png");
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover; 
}

.hellNight{
  background-image: url("./hellNight.png");
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover; 
}

.snowman{
  background-image: url("./snowman.png"); 
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover; 
}




.sliderWrap .btnClose, .sliderWrap .btnPrev, .sliderWrap .btnNext{
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  color: #fff;
  z-index: 9999;
}
.btnClose:hover,.btnPrev:hover,.btnNext:hover{
  opacity: 1;
}

.sliderWrap .btnClose{
  top: 40px;
  right: 40px;

}
.sliderWrap .btnPrev{
  top: 50%;
  transform: translateY(-50%);
  left: 40px;

}
.sliderWrap .btnNext{
  top: 50%;
  transform: translateX(-50%);
  right: 40px;

}

.fullScreenImage{
  width: calc(100% - 40px);
  height: calc(100%) -40px;
  display: flex;
  align-items: center;
  justify-content: center;

}

@media (max-width: 768px) {
  .perfume, .pilgrimage, .hellNight, .snowman, .orphan {
    background-image: none;
    background-color: rgba(141, 138, 138, 0.9); 
    
  }
  
  
  .tabs{
    margin: auto;
    width: 100%;
  }
  
  .tabs button{
    font-weight: 300;
    font-size: 16px;
    text-align: left;
  }
  

  .galleryWrap .gallery-item img{
      width: 350px;
      height: 300px;
  }
 
}
@media (max-width: 576px) {
  .tabs{
    margin: auto;
    width: 100%;
  }
  .tabs button{
    background-color: #fff;
    padding: 7px 3px 7px 2px;
    font-size: 12px;
    
  }
    .movieTitle{
      font-family: sans-serif !important;
      padding: 20px;
      font-weight:400;
    
    }
  
    .galleryWrap .gallery-item img{
      width: 350px;
      height: 300px;
  }
}



/* 
.the-gallery{
  display: flex;
}
.tabs{
  margin-top: 80px;
  display: flex;
  justify-content: start;
  flex-direction: column;
  margin-left: 130px;
  padding-right: -100px;

}

.tabs button{
  width: 350px;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-style: solid;
  border-color: rgb(241, 239, 239);
  border-width: 1px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  border-style: solid;
  border-color: #94112D;
  border-width: 3px;
  }
.tabs button:hover{
  box-shadow:  #550a1a 0px 5px 15px;

}

.galleryWrap{
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  align-items: center;
  max-width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 50px;
  padding-bottom: 50px;
  align-items: start;
}

.movieTitle{
  font-family:sans-serif !important;
  padding: 20px 0 20px 50px;
  font-weight:bold;
  font-size:xx-large;
  font-style: italic;
  text-align: left;
  color: #94112D;
  font-size: 30px;

}

.galleryWrap .gallery-item{
  width: 400px;
  cursor: pointer;

}

.galleryWrap .gallery-item img{
    width: 400px;
    height: 300px;
    
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.galleryWrap .gallery-item img:hover{
  transform: scale(1.02);
}
.sliderWrap{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0,0, 0, 0.9);
  width: 100%;
  height: 100%;

}
.orphan{
  background-image: url("./orphan.png");
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover; 
}

.perfume{
  background-image: url("./perfume.png");
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover; 
  
}

.pilgrimage{
  background-image: url("./pilgrimage.png");
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover; 
}

.hellNight{
  background-image: url("./hellNight.png");
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover; 
}

.snowman{
  background-image: url("./snowman.png"); 
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover; 
}




.sliderWrap .btnClose, .sliderWrap .btnPrev, .sliderWrap .btnNext{
  position: fixed;
  cursor: pointer;
  opacity: 0.7;
  color: #fff;
  z-index: 9999;
  width: 25px;
  height: 25px;
}
.btnClose:hover,.btnPrev:hover,.btnNext:hover{
  opacity: 1;
}

.sliderWrap .btnClose{
  top: 40px;
  right: 40px;

}
.sliderWrap .btnPrev{
  top: 50%;
  transform: translateY(-50%);
  left: 40px;

}
.sliderWrap .btnNext{
  top: 50%;
  transform: translateX(-50%);
  right: 40px;

}

.fullScreenImage{
  width: calc(100% - 40px);
  height: calc(100%) -40px;
  display: flex;
  align-items: center;
  justify-content: center;

}

@media (max-width: 768px) {
  .perfume, .pilgrimage, .hellNight, .snowman, .orphan {
    background-image: none;
    background-color: rgba(141, 138, 138, 0.9); 
    
  }
  
  
  .tabs{
    margin: auto;
    width: 300px;
  }
  .tabs button{
    background-color: #fff;
    padding: 5px;
    margin: 5px;
    border-style: solid;
    border-color: rgb(241, 239, 239);
    border-width: 1px;
    border-radius: 10px 10px 0 0;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 12px;
    }
    .galleryWrap{
      margin: 10px auto;
      width: 300px;
      
    }

    .galleryWrap .gallery-item img{
      width: 350px;
      height: 300px;
  }
 
}
@media (max-width: 576px) {
  .tabs{
    margin: auto;
    width: 300px;
  }
  .tabs button{
    background-color: #fff;
    padding: 7px;
    margin: 5px;
    font-size: 12px;
    
    }
    .galleryWrap{
      margin: 10px auto;
      width: 300px;
      
    }

    .galleryWrap .gallery-item img{
      width: 350px;
      height: 300px;
  }
} */