main.film h1 {
  font-size: 2rem;
  font-weight: 700;
  color: #94211d;
}
main.film h2 {
  color: #94211d;
  font-size: 1rem;
}
main.film section.synopsis p {
  text-align: justify;
  line-height: 1.2rem;
}
main.film section.synopsis .quote p {
  color: #94211d;
}

main.film audio {
 max-width: 250px;
  height: 20px;
}

audio progress {
width: 100px;
}

main.film img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#myHeart{
  width: 33%;
  height: 50%;
  object-fit: cover;
}
main.film img.header {
  height: 180px;
  width: auto;
}

.audio-poem{
  line-height: 1.3rem;
}

.grey-box{
  background-color: #E0E0E0;
  height: 100%;

}
.synopsis{
  font-size: 1.3rem;
}
.between-desc-section{
  display: flex;
  justify-content: center;
}

.snowman-posters{

  display: block;
  margin-left: auto;
  margin-right: auto;
  
}
.fluidImg{
  padding-bottom: 35px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.snowman-poster-left{
  width: 20%;
  height: 10%;
  
}

.between-overlay-sonce{
  position: relative;
  top: -210px;
  left: 700px;
  width: 300px;
}

.between-overlay-soleil{
  position: relative;
  left: 700px;
  width: 300px;
  bottom: 70px;

}
.between-overlay-sun{
  position: relative;
  bottom: 100px;
  left: 20px;
  width: 300px;
  
}

.hnn-button {
  display : flex;
  flex-direction : row;
  align-content : center;
   justify-content : space-evenly;
    margin-top : 20px;
    margin-bottom : 40px;
}
.fw-45 {
  width: 45%;
}

.fw-40 {
  width : 40%;
}

.fw-60 {
  width : 60%;
}

.snowman-poster-right{
  width: 60%;
  height: 40%;
  object-fit: cover;
}

.hnn-banner {
  width: 100%;
  object-fit : fill;
}
.hnn-poster{
  display: flex;
  gap : 70px;
}


.hnn-img {
  width : 35%;
}

.hnn-desc {
  display : flex;
  flex-direction : column; 
  justify-content : space-between;
  text-align : justify;
  width : 65%; 
  font-size : 18px;
  line-height : 1.2em;
  margin-left : 40px;
}
 
.hnn-trailer {
   display: flex; 
   align-content: center;
}

.hnn-iframe{
  margin-left : 40px;
}

.redux-info{
  display : flex; 
  justify-content: space-around;
  font-size : 1vw;
}

.redux-desc {
  display : flex;
  font-size: 18px;
  flex-direction : column;
  justify-content : space-between;
  text-align : justify;
}
.redux-poster{
  width : 100%;
}

.orphan-info {
  display: flex;
  gap: 30px;
  /* Space between the two posters */
  justify-content: center;
  /* Center the posters horizontally */
  align-items: center;
  /* Center the posters vertically */
  flex-wrap: wrap;
  /* Allow wrapping on smaller screens */
}


.orphan-info img {
  width: 100%;
  /* Make the posters take up full width of their container */
  height: 400px;
  /* Maintain aspect ratio */
  object-fit: cover;
  /* Cover the container while maintaining aspect ratio */
  border-radius: 16px;
  /* Rounded corners */
}

@media (max-width: 768px) {

  /* For smaller screens, stack the posters vertically */
  .poster-container {
    max-width: 75%;
    /* Each container takes full width */
  }

  .poster-image {
    height: 300px;
    /* Adjust height for smaller screens */
  }
}


.pilgrim-section{
  text-align : justify;
  padding : 30px;
  line-height : 1.4em;
  display : flex;
  justify-content : space-between;
  gap : 40px;
}

.style-bold {
  font-weight:  bold;
}

.style-italic {
  font-style: italic;
}

.color-theme {
  color: #94211d;
}

.margin-main {
  margin-left: 5vw;
  margin-right: 5vw;
}

.pilgrim-desc {
  text-align : justify;
  font-size: 18px;
  line-height : 1.2em;
  display : flex;
  flex-direction: column;
  justify-content : space-between;
  flex : 1;
}

.image-desc-flex {
  display: flex;
  flex-direction: row;
  gap : 80px;
}

.fw-50{
  width: 50%;
}


.fw-20 {
  width : 20%;
}


.fw-80 {
  width : 80%;
}

.fw-30 {
  width: 30%;
}

.fw-25 {
  width : 25%;
}

.fw-75 {
  width : 75%;
}

.fw-35 {
  width : 35%;
}

.fw-65 {
  width : 65%;
}

.snowman-text {
  font-weight : 600;  
  font-size: 0.8vw;
  line-height: 1.1em;
}

.snowman-details {
  padding: 10px 5px 10px 5px;
  display: flex; 
  flex-direction: column; 
  justify-content: space-between;
  text-align: justify;
  line-height: 1.15em;
  font-size: 18px;
}



.pilgrim-sub{
  display : flex; 
  justify-content : space-around;
  line-height : 1.2em;
}

@media (max-width: 1200px) {
  main.film h1 {
    font-size: 1.7rem;
  }
  main.film section.synopsis p {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
  
@media (min-width: 768px) {
  .orphan-info {
    flex-direction: row;
    gap: 30px;
    justify-content: center;
    
  }

  .orphan-info > .fw-35 img {
    width: 35%;
    height: auto;
    object-fit: contain;
  }

  .orphan-desc {
    width: 65%;
    font-size: 18px;
    line-height: 1.5rem;
    text-align: justify;
  }
}
  @media (max-width: 767.98px) {
  .orphan-info {
    flex-direction: column; /* Stack image and text vertically on small screens */
    gap: 20px;
  }

  .orphan-info > .fw-35 img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .orphan-desc {
    font-size: 16px;
  }
}
  
  .bissellFund{
    font-size: 11px;
  }
  .snowman-text {
    font-size: 18px;
  }

  .snowman-details{
    font-size: 18px;
  }
 

  .hnn-button {
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
  }
  .hnn-listen{
    font-size: "12px";
  }

  .pilgrim-desc{
    font-size: 18px;
  }

  .image-desc-flex {
    flex-direction: column;
    gap : 40px;
  }

.fw-20 {
  width : 100%;
}

.fw-25 {
  width : 100%;
}

.fw-30 {
  width : 100%;
}

.fw-45 {
  width : 100%;
}

.fw-80 {
  width : 100%;
}

.fw-50{
  width: 100%;
}
}

@media (max-width: 1280px) {
  .between-overlay-sonce {
    position: relative;
    top: -340px;
    left: 650px;
}

.between-overlay-soleil {
  position: relative;
  left: 550px;
  top: -60px;
}


  .between-overlay-sun{
    position: relative;
    top: -125px;
    left: 150px;    
  }
  
  main.film h1 {
    font-size: 1.5rem;
  }


  .margin-main {
    margin-left: 0vw;
    margin-right: 0vw;
  }

  .fw-45 {
    width: 100%;
  }
  .fw-30 {
    width: 100%;
  }

  .fw-25 {
    width: 100%;
}

.fw-75 {
    width: 100%;
}
  .redux-poster{
    width : 100%;
  }

  .fw-50{
    width: 100%;
  }
  
  .image-desc-flex {
    flex-direction: column;
    gap : 40px;
  }

  .redux-info{
    flex-direction: column;
    font-size : 14px;
  }

  .orphan-iframe{
    padding-left: 100px;
    padding-right: 100px;
  }

  .hnn-banner{
    height: auto;
  }

  .hnn-poster{
    flex-direction: column;
  }

  .hnn-img{
    width: 100%;
  }

  .hnn-iframe{
    margin-top: 20px;
    margin-left : 0px;

  }
  
  .orphan-info{
    flex-direction: column;
    height: 90%;
  }


  .fw-25 {
    width: 100%;
}

.fw-75 {
    width: 100%;
}

  .hnn-desc{
    width: 100%;
    margin-left : 0px;
    margin-top: 20px;
    font-size: 18px;
  }
  .hnn-trailer {
    flex-direction: column;
  }

  .fw-40 {
    width : 100%;
  }
  
  .fw-60 {
    width : 100%;
  }
  

  .fw-35 {
    width : 100%;
  }
  
  .fw-65 {
    width : 100%;
  }
  

  .pilgrim-section{

    flex-direction: column;
  }

  .pilgrim-sub{
    flex-direction: column;
  }
  
  .pilgrim-interview{
    margin-top: 20px;
  }
}
@media (max-width: 854px) {
  .between-overlay-sonce {
    position: relative;
    top: -240px;
    left: 350px;
}
  
  .between-overlay-soleil {
    position: relative;
    left: 350px;
    top: -65px;
}

  .between-overlay-sun{
    position: relative;
    top: -110px;
    left: 50px;

    
  }
}

@media (min-width: 480px) {
  .between-overlay-sonce {
    position: relative;
    top: -180px;
    left: 180px;
}
  
  .between-overlay-soleil {
    position: relative;
    left: 160px;
    top: -70px;
}

  .between-overlay-sun{
    position: relative;
    top: -100px;
    left: 30px;
  }

}

@media (min-width: 1600px) {
  .orphan-desc, .hnn-desc, .pilgrim-desc, .snowman-details{
    font-size: 1.2vw;
  }

@media (max-width: 480px) {
  .orphan-desc {
    font-size: 14px; /* Further reduce text size for very small screens */
  }
}
  
}

@media (min-width: 1970px) {
  .between-overlay-sonce {
    position: relative;
    top: -400px;
    left: 850px;
  }

  .between-overlay-soleil {
    position: relative;
    left: 700px;
    top: -70px;
  }


  .between-overlay-sun {
    position: relative;
    top: -135px;
    left: 190px;
  }
}

@media (min-width: 1468px) {
  .between-overlay-sonce {
    position: relative;
    top: -350px;
    left: 650px;
  }

  .between-overlay-soleil {
    position: relative;
    left: 700px;
    top: -70px;
  }


  .between-overlay-sun {
    position: relative;
    top: -135px;
    left: 190px;
  }
}

@media (min-width: 1100px) {
  .between-overlay-sonce {
    position: relative;
    top: -350px;
    left: 650px;
  }

  .between-overlay-soleil {
    position: relative;
    left: 500px;
    top: -70px;
  }


  .between-overlay-sun {
    position: relative;
    top: -135px;
    left: 190px;
  }

}