

@charset "UTF-8";
main div.header {
  width: 100%;
}
main div.header img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
main div.intro h2 {
  font-family: sans-serif !important;
  font-size: .2 rem;
  font-weight: normal;
  line-height: 1.5;
  text-align: justify;
  text-justify: inter-word;
}
main section.films h2 {
  color: #94211d;
  font-size: 1.7rem;
  font-weight: 600;
  padding: 15px;
}

audio::-webkit-media-controls-panel {
  margin: 0px;
  padding: 0px;
}

audio::-webkit-media-controls-enclosure {
  /* background-color: #B1D4E0; */
  width: 80%;
}

audio::-webkit-media-controls-volume-slider {  display: none; }

audio::-webkit-media-controls-mute-button {  display: none; }

.home-media{
  margin-top: 80px;
  text-align: center;
}

.indev {

  height: 100%;
}

main section#quote-container {
  font-size: 1.8rem;
  font-weight: 600;
  color: #94211d;
}
main section#quote-container h3 {
  line-height: 1.5;
  text-align: justify;
  text-justify: inter-word;
}
main section#quote-container h3 span {
  font-weight: 400;
}
main section#quote-container h3 span::before {
  content: "— ";
  font-family: serif;
}
main section#quote-container p {
  font-weight: 400;
}
main section#quote-container p::before {
  content: "— ";
  font-family:  serif;
}

#bottom-image{

}

.book-centered-div {
  display: flex;
  background-color: #f0f0f0;
  border-radius: 12px;
  width: 100%;
  justify-content: center; /* Center horizontally */
  align-content: center; /* Center vertically */
}

.banner-stretch{
  
}

/* .two-book-boxes{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
} */

.two-book-boxes {
  display: flex;
  justify-content: space-evenly; /* Space items evenly in the container */
  flex-wrap: wrap; /* Allow items to wrap to the next line if needed */
}

.two-book-boxes-item-left{
  display: flex;
  justify-content: space-between;
  align-items: center;
 /* Allow each child div to take equal space */
  padding: 10px; /* Add spacing between the image and text */
  text-align: center; /* Center the text within the div */
  width: 35%; /* Set the width for each item to 70% of the container width */
  box-sizing: border-box; /* Include padding in the width calculation */
  margin: 30px; /* Add margin to create spacing between items */
}



.two-book-boxes-item {
/* Allow each child div to take equal space */
  padding: 10px; /* Add spacing between the image and text */
  text-align: center; /* Center the text within the div */
  width: 35%; /* Set the width for each item to 70% of the container width */
  box-sizing: border-box; /* Include padding in the width calculation */
  margin: 20px; 
}


.two-book-boxes-item-img {
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.book-title {
  color: #94211d;
  font-size: 1.7rem;
  font-weight: 600;
  padding: 15px;
  height: 100%;
}

section {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content horizontally */
}

.book-image-row {
  margin-top: 20px;
  display: flex; /* Display the images in a row */
  justify-content: flex-start; /* Align the images to the left edge */
  gap: 20px; /* Adjust the gap between images as needed */

}

.book-image {
  flex: 1; /* Distribute available space equally among images */
  /* padding : 10px; */
  overflow: hidden;
}

.book-image-partial{
  flex: 0 0 25%;
}

.book-image img {
  width: 100%; /* Set the image width to 100% of the div's width */
  height: 100%; /* Set the image height to 100% of the div's height */
  /* object-fit: cover;  */
}



.book-box-item-right{
  text-align: center;
  background-color: #FFC72C;
  height: 450px;
  border-left: 30px solid white;
  border-right: 50px solid white;
}

.book-box-item-left{
  text-align: center;
  background-color: #33C9FF ;
  height: 450px;
  border-right: 30px solid white;
  border-left: 50px solid white;
  font-family: serif !important;
  font-weight: bold;
  padding: 20px;
}

.media-project-item{
  /* border-radius: 12px; */
}

#book-box-text-smallest h3{
  line-height: 1.2rem ; 
  font-family: serif !important;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  
} 

.book-headers {
  line-height: 1.2rem ; 
  font-family: serif !important;
  font-weight: bold;
  font-size: 1rem;
}

#book-box-text-small h3{
  line-height: 1.5rem ; 
  font-family: serif !important;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  
} 

#book-box-text{
  line-height: 1.5rem ; 
  font-family: serif !important;
  font-weight: bold;
  font-size: 1rem;
  
}

.image-container {
  position: relative; /* Make the container a positioning context */
}

figcaption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-color: rgba(0, 0, 0, 0.2);  */
  background: linear-gradient(#00000016, #000000a5);
  color: white; /* Text color for the caption */
  padding: 8px; /* Adjust padding as needed */
  text-align: center; /* Center the caption text */
}


.small-blue-box{
  text-align: center;
  background-color: #33C9FF ;
  height: 400px;
  border-bottom: 50px solid white;
  font-family: serif !important;
  font-weight: bold;
}

.small-yellow-box{
  text-align: center;
  background-color: #FFC72C;
  height: 400px;;
  border-bottom: 50px solid white;
 
  
}


.future-film-img {
  border-radius: 12px;
  width: 100%;
  height: auto;
}

.container-caption {
  position: relative; /* Make the container a positioning context */
}

.caption {
  position: absolute;
  bottom : 0; /* Adjust the top position to vertically center the caption */
  left: 0; /* Adjust the left position to horizontally center the caption */ /* Center the caption perfectly */
  background-color: rgba(0, 0, 0, 0.252); /* Add a background color for readability */
  color: white; /* Set the text color */
  padding: 5px; /* Add padding for better appearance */

}

.snowman-dist {
  display : flex;
  justify-content : space-between;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.252);
  color: white;
  padding: 5px;
}

.future-film-text {
  flex: 1; /* Allow text to grow and take available space */
}


.future-film-image {
  flex: 1; /* Allow image to grow and take available space */
  display: flex;
  align-items: center; /* Vertically center the image within its container */
}

.future-film-img {
  max-height: 100%; /* Ensure image height matches its container height */
  width: auto; /* Allow image width to adjust proportionally */
}


.future-film-text {
  flex: 1; /* Allow text div to grow and take available space */
}

.future-film-image {
  flex: 1; /* Allow image div to grow and take available space */
  display: flex;
  align-items: center; /* Vertically center the image within its container */
}

.future-film-img {
  max-height: 100%; /* Ensure image height matches its container height */
  width: auto; /* Allow image width to adjust proportionally */
}

.modals-im{
  display: block;
  position: fixed; 
  z-index: 999;
  padding-top: 100px; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  background-color: rgb(0, 0, 0); 
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content-img {
  margin: auto;
  display: block;
  max-width: 600px;
  border-radius: 12px;
  background-color: rgb(0, 0, 0); 
}

.modal-img-container {
  border-radius: 12px;
}

.modal-img {
  border-radius: 12px;
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.home-media-wid{
  width: 82%;
  margin: 0 auto;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
.projects-section{
  margin-top: 60px;
  margin-bottom : 60px;
}

.home-section-padding {
  padding-left: 50px ;
  padding-right: 50px;
}

.hr-sep{
  margin-top : 60px;
}

@media (min-width: 992px) {
  main section.films div.book-row {
    padding-left: 1rem !important;
  }
}
@media (max-width: 991.98px) {
  main div.intro {
    font-size: 1.5rem;
  }
  main section.films h2 {
    font-size: 1.5rem;
  }
  main section.films h3 {
    font-size: 1.2rem;
  }
  main section.films div.film-poster div.row {
    background-color: transparent;
  }
  .two-book-boxes-item-left{
    width: 80%;
    margin: 0;
    margin-top: 20px;
  }

  .two-book-boxes-item{
    width: 80%;
    margin: 0;
    margin-top: 20px;
  }
}
@media (max-width: 767.98px) {
  main div.intro h2 {
    font-size: 1rem;
    line-height: 1.4;
  }

  .hr-sep{
    margin-top: 40px;
  }

  .book-image-row{
    flex-direction: column;
  }

  .projects-section{
    margin-top: 20px;
    margin-bottom : 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  

  .book-image-partial{
    flex : 1;
  }

  .book-title{
    font-size : 18px;
  }

  main section.films h2 {
    font-size: 1.3rem;
  }
  main section.films h3 {
    font-size: 1.2rem;
  }

  .home-section-padding{
    padding-left: 10px;
    padding-right: 10px;
  }

  .home-media{
    width: 100% !important;
  }

  .home-media-wid{
    width : 100%;
  }

  .two-book-boxes-item-left{
    width: 100%;
    margin: 0;
    margin-top: 20px;
  }

  .two-book-boxes-item{
    width: 100%;
    margin: 0;
    margin-top: 20px;
  }
  
}
@media (max-width: 576px) {
  main section#quote-container h3, main section#quote-container p {
    font-size: 1rem;
  }
}


