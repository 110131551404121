/* body, html {
  overflow-y: auto; 
  height: 100%;
  margin: 0;
  padding: 0;
} */

@media screen and (min-width: 961px) {
  .app-outer-div {
    background-color: rgb(255, 255, 255);
    /* height: 100vh; */
    min-height: 100vh;
    /* width: 100vw; */
    position: relative;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    color: #2e2e2e;
    font-family: 'Martel', Georgia, 'Times New Roman', Times, serif;
  }

}


@media screen and (max-width: 960px) {
    .app-outer-div {
        background-color: rgb(255, 255, 255);
        /* height: 100vh; */
        /* min-height: 100vh; */
        /* width: 100vw; */
        position: relative;
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        color: #2e2e2e;
        font-family: 'Martel', Georgia, 'Times New Roman', Times, serif;
      }
}